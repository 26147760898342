// BasicDemo.tsx
import React, { useState, useEffect, useRef } from 'react';
import Select, {
  components,
  OptionProps,
  SingleValueProps,
  OnChangeValue,
} from 'react-select';
import { Phone, Heart, Gavel, Home, Briefcase } from 'lucide-react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import bgImage from '../assets/bg-demo.jpg';
import '../assets/BasicDemo.css'; // Import the custom CSS
import LeftBox from './LeftBox';
import RightBox from './RightBox';
import axios from 'axios'; // For making API requests

interface OptionType {
  value: string;
  label: string;
  icon: React.ReactNode;
}

interface UserInfo {
  name: string;
  phone: string;
  professionalLabel?: string;
  professionalName?: string;
  issueLabel?: string;
  issue?: string;
}

interface CalendarEvent {
  date: string; // DD-MM-YYYY
  time: string;
  label: string;
  color: string;
}

const options: OptionType[] = [
  { value: '1', label: 'Healthcare', icon: <Heart /> },
  { value: '2', label: 'Legal', icon: <Gavel /> },
  { value: '3', label: 'Real Estate', icon: <Home /> },
  { value: '4', label: 'Services', icon: <Briefcase /> },
];

const Option = React.memo((props: OptionProps<OptionType, false>) => (
  <components.Option {...props}>
    <span className="flex items-center text-2xl">
      {props.data.icon}
      <span className="ml-2">{props.data.label}</span>
    </span>
  </components.Option>
));

const SingleValue = React.memo((props: SingleValueProps<OptionType, false>) => (
  <components.SingleValue {...props}>
    <span className="flex items-center text-2xl">
      {props.data.icon}
      <span className="ml-2">{props.data.label}</span>
    </span>
  </components.SingleValue>
));

const BasicDemo: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState<OptionType | null>(options[0]);
  const [phone, setPhone] = useState<string>('');
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [calendarEvents, setCalendarEvents] = useState<CalendarEvent[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const [serviceType, setServiceType] = useState<number | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [domain, setDomain] = useState<string>('1');

  const pollingRef = useRef<number | null>(null);

  useEffect(() => {
    if (selectedOption) {
      setDomain(selectedOption.value);
    }
  }, [selectedOption]);

  const handleCallButtonClick = async () => {
    if (!selectedOption || !phone) {
      alert('Please select a service and enter a valid phone number.');
      return;
    }

    setLoading(true);
    setError('');
    setUserInfo(null);
    setCalendarEvents([]);
    setServiceType(null);
    setMessage(null);

    try {
      const payload = {
        service_type: parseInt(selectedOption.value, 10),
        phone_number: `+${phone}`, // Corrected syntax
      };

      const response = await axios.post('https://reception.vebtual.com/public/api/vapi/call', payload);
      const { callId } = response.data;

      if (callId) {
        pollCallStatus(callId as string, 0);
      } else {
        setError('Invalid response from server.');
        setLoading(false);
      }
    } catch (err) {
      console.error(err);
      setError('Failed to initiate call. Please try again.');
      setLoading(false);
    }
  };

  const pollCallStatus = async (callId: string, attempt: number) => {
    const maxAttempts = 60;
    const delay = 5000;

    try {
      const response = await axios.get(`https://reception.vebtual.com/public/api/vapi/call/${callId}`);

      if (response.data.error) {
        setError(response.data.error);
        setLoading(false);
        return;
      }

      if (response.data.status) {
        // still in progress
        if (attempt < maxAttempts) {
          const timer = window.setTimeout(() => {
            pollCallStatus(callId, attempt + 1);
          }, delay);
          pollingRef.current = timer;
        } else {
          setError('Call timed out. Please try again later.');
          setLoading(false);
        }
      } else {
        const data = response.data;
        const scenario = parseInt(data.service_type, 10);
        setServiceType(scenario);
        if (domain === '1') {
          handleHealthcareScenario(data, scenario);
        } else if (domain === '2') {
          handleLegalScenario(data, scenario);
        } else if (domain === '3') {
          handleRealEstateScenario(data, scenario);
        } else if (domain === '4') {
          handleProfessionalScenario(data, scenario);
        } else {
          setError('Domain not implemented yet.');
          setLoading(false);
        }
      }
    } catch (err) {
      console.error(err);
      if (attempt < maxAttempts) {
        const timer = window.setTimeout(() => {
          pollCallStatus(callId, attempt + 1);
        }, delay);
        pollingRef.current = timer;
      } else {
        setError('Failed to fetch call status. Please try again later.');
        setLoading(false);
      }
    }
  };

  // Healthcare domain scenarios
  const handleHealthcareScenario = (data: any, scenario: number) => {
    const safeFullName = data.full_name || '';
    const safePhone = phone.startsWith('+') ? phone : `+${phone}`; // Corrected syntax

    if (scenario === 1) {
      const { doctor, issue, appointment_date, appointment_time } = data;
      setUserInfo({
        name: safeFullName,
        phone: safePhone,
        professionalLabel: 'Doctor',
        professionalName: doctor || '',
        issueLabel: 'Issue',
        issue: issue || '',
      });

      setCalendarEvents([
        {
          date: appointment_date, // Assuming it's in DD-MM-YYYY
          time: appointment_time,
          label: 'Appointment',
          color: 'green',
        },
      ]);
      setLoading(false);
    } else if (scenario === 2) {
      const { doctor, appointment_date, appointment_time, new_appointment_date, new_appointment_time } = data;
      setUserInfo({
        name: safeFullName,
        phone: safePhone,
        professionalLabel: 'Doctor',
        professionalName: doctor || '',
      });

      setCalendarEvents([
        {
          date: appointment_date, // DD-MM-YYYY
          time: appointment_time,
          label: 'Old Appointment',
          color: 'red',
        },
        {
          date: new_appointment_date, // DD-MM-YYYY
          time: new_appointment_time,
          label: 'New Appointment',
          color: 'green',
        },
      ]);
      setLoading(false);
    } else if (scenario === 3) {
      const { doctor, message } = data;
      setUserInfo({
        name: safeFullName,
        phone: safePhone,
        professionalLabel: 'Doctor',
        professionalName: doctor || '',
      });
      setMessage(message || '');
      setCalendarEvents([]);
      setLoading(false);
    } else if (scenario === 4) {
      const { inquiry_type, inquiry_details } = data;
      setUserInfo({
        name: safeFullName,
        phone: safePhone,
        issueLabel: 'Inquiry',
        issue: inquiry_type || '',
        professionalLabel: 'Details',
        professionalName: inquiry_details || '',
      });
      setCalendarEvents([]);
      setLoading(false);
    } else {
      setError('Unrecognized service type for Healthcare.');
      setLoading(false);
    }
  };

  // Similar updates for other domain scenario handlers...
  // Ensure all dates are in DD-MM-YYYY format when setting calendarEvents

  // Legal domain scenarios
  const handleLegalScenario = (data: any, scenario: number) => {
    const safeFullName = data.full_name || '';
    const safePhone = phone.startsWith('+') ? phone : `+${phone}`;

    if (scenario === 1) {
      const { attorney, legal_issue, consultation_date, consultation_time } = data;
      setUserInfo({
        name: safeFullName,
        phone: safePhone,
        professionalLabel: 'Attorney',
        professionalName: attorney || '',
        issueLabel: 'Legal Issue',
        issue: legal_issue || '',
      });

      setCalendarEvents([
        {
          date: consultation_date, // DD-MM-YYYY
          time: consultation_time,
          label: 'Consultation',
          color: 'green',
        },
      ]);
      setLoading(false);
    } else if (scenario === 2) {
      const { attorney, consultation_date, consultation_time, new_consultation_date, new_consultation_time } = data;
      setUserInfo({
        name: safeFullName,
        phone: safePhone,
        professionalLabel: 'Attorney',
        professionalName: attorney || '',
      });

      setCalendarEvents([
        {
          date: consultation_date, // DD-MM-YYYY
          time: consultation_time,
          label: 'Old Consultation',
          color: 'red',
        },
        {
          date: new_consultation_date, // DD-MM-YYYY
          time: new_consultation_time,
          label: 'New Consultation',
          color: 'green',
        },
      ]);
      setLoading(false);
    } else if (scenario === 3) {
      const { attorney, message } = data;
      setUserInfo({
        name: safeFullName,
        phone: safePhone,
        professionalLabel: 'Attorney',
        professionalName: attorney || '',
      });
      setMessage(message || '');
      setCalendarEvents([]);
      setLoading(false);
    } else if (scenario === 4) {
      const { inquiry_type, inquiry_details } = data;
      setUserInfo({
        name: safeFullName,
        phone: safePhone,
        issueLabel: 'Inquiry',
        issue: inquiry_type || '',
        professionalLabel: 'Details',
        professionalName: inquiry_details || '',
      });
      setCalendarEvents([]);
      setLoading(false);
    } else {
      setError('Unrecognized service type for Legal.');
      setLoading(false);
    }
  };

  // Real Estate domain scenarios
  const handleRealEstateScenario = (data: any, scenario: number) => {
    const safeFullName = data.full_name || '';
    const safePhone = phone.startsWith('+') ? phone : `+${phone}`;

    if (scenario === 1) {
      const { viewing_date, viewing_time, property_name } = data;
      setUserInfo({
        name: safeFullName,
        phone: safePhone,
        professionalLabel: 'Property',
        professionalName: property_name || '',
        issueLabel: 'Inquiry',
        issue: 'Property Viewing',
      });

      setCalendarEvents([
        {
          date: viewing_date, // DD-MM-YYYY
          time: viewing_time,
          label: 'Viewing',
          color: 'green',
        },
      ]);
      setLoading(false);
    } else if (scenario === 3) {
      const { property_name, viewing_date, viewing_time, new_viewing_date, new_viewing_time } = data;
      setUserInfo({
        name: safeFullName,
        phone: safePhone,
        professionalLabel: 'Property',
        professionalName: property_name || '',
      });

      setCalendarEvents([
        {
          date: viewing_date, // DD-MM-YYYY
          time: viewing_time,
          label: 'Old Viewing',
          color: 'red',
        },
        {
          date: new_viewing_date, // DD-MM-YYYY
          time: new_viewing_time,
          label: 'New Viewing',
          color: 'green',
        },
      ]);
      setLoading(false);
    } else if (scenario === 4) {
      const { inquiry_type, inquiry_details } = data;
      setUserInfo({
        name: safeFullName,
        phone: safePhone,
        issueLabel: 'Inquiry',
        issue: inquiry_type || '',
        professionalLabel: 'Details',
        professionalName: inquiry_details || '',
      });
      setCalendarEvents([]);
      setLoading(false);
    } else {
      setError('Unrecognized service type for Real Estate.');
      setLoading(false);
    }
  };

  // Professional Services domain scenarios
  const handleProfessionalScenario = (data: any, scenario: number) => {
    const safeName = data.name || '';
    const safePhone = phone.startsWith('+') ? phone : `+${phone}`;

    if (scenario === 1) {
      const { demo_company, appointment_date, appointment_time } = data;
      setUserInfo({
        name: safeName,
        phone: safePhone,
        professionalLabel: 'Service Provider',
        professionalName: demo_company || '',
        issueLabel: 'Service',
        issue: 'Appointment',
      });

      setCalendarEvents([
        {
          date: appointment_date, // DD-MM-YYYY
          time: appointment_time,
          label: 'Appointment',
          color: 'green',
        },
      ]);
      setLoading(false);
    } else if (scenario === 2) {
      const { demo_company, appointment_date, appointment_time, new_appointment_date, new_appointment_time } = data;
      setUserInfo({
        name: safeName,
        phone: safePhone,
        professionalLabel: 'Service Provider',
        professionalName: demo_company || '',
      });

      setCalendarEvents([
        {
          date: appointment_date, // DD-MM-YYYY
          time: appointment_time,
          label: 'Old Appointment',
          color: 'red',
        },
        {
          date: new_appointment_date, // DD-MM-YYYY
          time: new_appointment_time,
          label: 'New Appointment',
          color: 'green',
        },
      ]);
      setLoading(false);
    } else if (scenario === 3) {
      const { demo_company, enquiry_type, enquiry_details } = data;
      setUserInfo({
        name: safeName,
        phone: safePhone,
        issueLabel: 'Inquiry',
        issue: enquiry_type || '',
        professionalLabel: 'Details',
        professionalName: enquiry_details || '',
      });
      setCalendarEvents([]);
      setLoading(false);
    } else {
      setError('Unrecognized service type for Professional Services.');
      setLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      if (pollingRef.current) {
        clearTimeout(pollingRef.current);
      }
    };
  }, []);

  return (
    <div
      className="min-h-screen flex flex-col justify-center items-center text-center py-8 px-4 lg:py-12 lg:px-6 relative"
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {/* Headings and Description */}
      <div className="mb-8 px-4">
        <h1 className="text-black text-center text-4xl sm:text-5xl md:text-6xl lg:text-7xl leading-tight first-heading">
          Your Virtual Receptionist,
        </h1>

        <h2 className="text-black text-center font-bold mt-2 text-4xl sm:text-5xl md:text-6xl lg:text-7xl first-heading">
          Supercharged
        </h2>

        <p className="text-black text-center mt-6 px-4 sm:px-6 md:px-8 max-w-md mx-auto text-sm sm:text-base lg:text-lg description">
          Connect your Google Calendar and CRM in seconds.
          Simulate a live call and see leads and bookings sync instantly!
        </p>
      </div>

      <div
        className="w-full max-w-screen-xl mx-auto grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-8 items-stretch h-full"
      >
        {/* Main Column */}
        <div className="order-1 lg:order-2 flex flex-col items-center h-full">
          <Select<OptionType, false>
            value={selectedOption}
            onChange={(newValue: OnChangeValue<OptionType, false>) => {
              if (newValue && !Array.isArray(newValue)) {
                setSelectedOption(newValue);
              }
            }}
            options={options}
            components={{ Option, SingleValue }}
            isSearchable={false}
            isClearable={false}
            className="w-full"
            styles={{
              container: (base) => ({
                ...base,
                width: '100%',
              }),
              control: (base) => ({
                ...base,
                backgroundColor: '#1E1E21',
                color: '#fff',
                borderRadius: '30px',
                border: 'none',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                minHeight: '60px',
                padding: '8px 12px',
                fontSize: '32px',
              }),
              singleValue: (base) => ({
                ...base,
                color: '#fff',
                fontSize: '32px',
                fontWeight: '600',
              }),
              menu: (base) => ({
                ...base,
                backgroundColor: '#000',
                borderRadius: '12px',
                marginTop: '8px',
              }),
              menuList: (base) => ({
                ...base,
                padding: '0',
              }),
              option: (base, state) => ({
                ...base,
                backgroundColor: state.isSelected
                  ? '#555'
                  : state.isFocused
                  ? '#444'
                  : '#000',
                color: '#fff',
                cursor: 'pointer',
                padding: '18px 24px',
                fontSize: '22px',
                transition: 'background-color 0.2s ease',
              }),
              dropdownIndicator: (base) => ({
                ...base,
                color: '#fff',
                padding: '8px',
              }),
              indicatorSeparator: () => ({
                display: 'none',
              }),
            }}
          />

          <div className="w-full mt-6">
            <PhoneInput
              country={'us'}
              value={phone}
              onChange={(newPhone) => setPhone(newPhone)}
              inputStyle={{
                width: '100%',
                height: '60px',
                backgroundColor: '#ffffff',
                borderRadius: '20px',
                paddingLeft: '60px',
                fontSize: '16px',
                border: '2px solid #3465E3',
              }}
              buttonStyle={{
                backgroundColor: '#D1E2F8',
                borderTopLeftRadius: '20px',
                borderBottomLeftRadius: '20px',
                padding: '10px',
                borderRight: '2px solid #3465E3',
              }}
              containerStyle={{
                width: '100%',
              }}
              inputProps={{
                name: 'phone',
                required: true,
                autoFocus: false,
                'aria-label': 'Phone number',
              }}
              enableSearch={false}
              disableCountryCode={false}
            />
          </div>

          <button
            onClick={handleCallButtonClick}
            className="flex items-center justify-center px-6 py-3 bg-blue-600 hover:bg-blue-700 text-white rounded-lg shadow-lg transition-colors duration-200 gap-2 w-40 sm:w-48 md:w-56 lg:w-40 mx-auto mt-6"
            disabled={loading}
          >
            <Phone className="w-5 h-5" />
            <span>{loading ? 'Calling...' : 'Get a Call'}</span>
          </button>

          {error && (
            <p className="text-red-500 mt-4">{error}</p>
          )}

          {loading && !error && (
            <p className="text-gray-700 mt-2 text-sm">
              Please stay on this page. You will see the call details once the call ends.
            </p>
          )}
        </div>

        {/* Left Box */}
        <div className="order-2 lg:order-1 flex flex-col h-full">
          {userInfo && (
            <LeftBox userInfo={userInfo} />
          )}
        </div>

        {/* Right Box */}
        <div className="order-3 lg:order-3 flex flex-col h-full">
          {serviceType === 3 && message ? (
            <RightBox events={calendarEvents} serviceType={serviceType} message={message} />
          ) : serviceType === 4 ? (
            <></>
          ) : calendarEvents.length > 0 ? (
            <RightBox events={calendarEvents} serviceType={serviceType} />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default BasicDemo;
