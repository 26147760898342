import React from 'react';
import PropTypes from 'prop-types';
import crmLogo from '../assets/crm.svg'; // Ensure the path is correct
import userImage from '../assets/user.svg'; // Ensure the path is correct
import '../assets/LeftBox.css'; // Import custom CSS if needed

const LeftBox = ({ className, userInfo }) => {
  return (
    <div
      className={`bg-white bg-opacity-50 p-6 flex flex-col items-center justify-center shadow-lg flex-1 max-w-lg ${className}`}
      style={{ borderRadius: '30px' }}
    >
      {/* Logo */}
      <img src={crmLogo} alt="CRM Logo" className="w-35 h-35 mb-6" />

      {/* User Section */}
      <div className="flex flex-col items-center">
        {/* User Image */}
        <img
          src={userImage}
          alt="User"
          className="w-24 h-24 rounded-full border-4 border-blue-500 mb-4"
        />

        {/* User Information */}
        <div className="w-full max-w-xs text-left">
          <p className="text-black text-lg mb-2">
            <span className="font-semibold">Name:</span> {userInfo.name}
          </p>
          <p className="text-black text-lg mb-2">
            <span className="font-semibold">Phone:</span> {userInfo.phone}
          </p>

          {userInfo.issue && (
            <p className="text-black text-lg mb-2">
              <span className="font-semibold">{userInfo.issueLabel}:</span> {userInfo.issue}
            </p>
          )}

          {userInfo.professionalName && (
            <p className="text-black text-lg mb-2">
              <span className="font-semibold">{userInfo.professionalLabel}:</span> {userInfo.professionalName}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

LeftBox.propTypes = {
  className: PropTypes.string,
  userInfo: PropTypes.shape({
    name: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    professionalLabel: PropTypes.string,
    professionalName: PropTypes.string,
    issueLabel: PropTypes.string,
    issue: PropTypes.string,
  }).isRequired,
};

LeftBox.defaultProps = {
  className: '',
};

export default LeftBox;
