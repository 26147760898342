import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; // Import default styles
import '../assets/RightBox.css'; // Import custom CSS for additional styling
import calendarLogo from '../assets/calender.svg'; // Ensure the path is correct
import gmailLogo from '../assets/gmail.svg'; // For messages
import Modal from 'react-modal';

Modal.setAppElement('#root');

const RightBox = ({ className, events, serviceType, message }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedDateEvents, setSelectedDateEvents] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);

  const formatDate = (date)=> {
    const day = (`0${date.getDate()}`).slice(-2);
    const month = (`0${date.getMonth() + 1}`).slice(-2);
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  if (serviceType === 3 && message) {
    // service_type=3 is message scenario in Healthcare/Legal
    return (
      <div
        className={`bg-white bg-opacity-50 p-6 flex flex-col items-center justify-start shadow-lg flex-1 max-w-lg ${className}`}
        style={{ borderRadius: '30px' }}
      >
        <img src={gmailLogo} alt="Message Logo" className="w-25 h-25 mb-4" />
        <h2 className="text-2xl mb-4 font-semibold">Message Sent:</h2>
        <p className="text-black text-lg">{message}</p>
      </div>
    );
  }

  const eventsByDate = events.reduce((acc, event) => {
    if (!acc[event.date]) {
      acc[event.date] = [];
    }
    acc[event.date].push(event);
    return acc;
  }, {});

  const handleDateClick = (date) => {
    const formattedDate = formatDate(date);
    const dayEvents = eventsByDate[formattedDate];
    if (dayEvents) {
      setSelectedDateEvents(dayEvents);
      setSelectedDate(formattedDate);
      setModalIsOpen(true);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedDateEvents([]);
    setSelectedDate(null);
  };

  return (
    <div
      className={`bg-white bg-opacity-50 p-6 flex flex-col items-center justify-start shadow-lg flex-1 max-w-lg ${className}`}
      style={{ borderRadius: '30px' }}
    >
      {/* Logo */}
      <img src={calendarLogo} alt="Calendar Logo" className="w-25 h-25 mb-4" />

      {/* Calendar */}
      <Calendar
        className="w-full"
        onClickDay={handleDateClick}
        tileContent={({ date, view }) => {
          if (view === 'month') {
            const formattedDate = formatDate(date);
            const dayEvents = eventsByDate[formattedDate];
            return (
              <div className="event-indicators">
                {dayEvents &&
                  dayEvents.map((event, index) => (
                    <span
                      key={index}
                      className="event-circle"
                      style={{
                        backgroundColor: `${event.color}80`,
                        border: `2px solid ${event.color}`,
                      }}
                      title={`${event.time} - ${event.label}`}
                    ></span>
                  ))}
              </div>
            );
          }
        }}
      />

      {/* Modal for Event Details */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Event Details"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <h2 className="text-2xl mb-4">Events on {selectedDate}</h2>
        <ul>
          {selectedDateEvents.map((event, index) => (
            <li key={index} className="mb-2 flex items-center">
              <span
                className="inline-block w-3 h-3 rounded-full mr-2"
                style={{ backgroundColor: event.color }}
              ></span>
              <strong>{event.time}:</strong> {event.label}
            </li>
          ))}
        </ul>
        <button onClick={closeModal} className="mt-4 px-4 py-2 bg-blue-600 text-white rounded">
          Close
        </button>
      </Modal>
    </div>
  );
};

RightBox.propTypes = {
  className: PropTypes.string,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      time: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    })
  ).isRequired,
  serviceType: PropTypes.number,
  message: PropTypes.string,
};

RightBox.defaultProps = {
  className: '',
  serviceType: null,
  message: null,
};

export default RightBox;
