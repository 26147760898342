import React from 'react';
import Hero from '../components/Hero';
import Features from '../components/Features';
import UseCases from '../components/UseCases';
import Contact from '../components/Contact';
import BasicDemo from '../components/BasicDemo';


export default function Home() {
  return (
    <div className="content-section">
      <Hero />
        <BasicDemo />
      <Features />
      <UseCases />
      <Contact />
    </div>
  );
}